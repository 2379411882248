// Syncfusion
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-notifications/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import "../node_modules/@syncfusion/ej2-angular-lists/styles/material.css";
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';

// swiper bundle styles
@import '../node_modules/swiper/swiper-bundle.min.css';

// swiper core styles
@import '../node_modules/swiper/swiper.min.css';

// Bootstrap
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

// Material theme
@import './assets/styles/material.min.css';

/*
 * 폰트 정의 시작
 * -------------------------------------------
 */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 100;
	src: url('./assets/fonts/roboto/Roboto-Thin.ttf') format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 100;
	src: url('./assets/fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url('./assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	src: url('./assets/fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('./assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('./assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 500;
	src: url('./assets/fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('./assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	src: url('./assets/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	src: url('./assets/fonts/roboto/Roboto-Black.ttf') format('truetype');
}
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 900;
	src: url('./assets/fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
}
/*
 * -------------------------------------------
 */

// Angular Material
html, body {
	height: 100%;
	background-color: #F7F7F9;
	color: #333333;
}

body {
	margin: 0;
	font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}

// a 태그 기본 초기화
a {
	text-decoration: none;
	color: #333333;
}

@import './assets/styles/toast_base';
@import './assets/styles/common';
