// Account 화면에 사용한 버튼의 공용스타일
.btn-success-type {
	width: 100%;
	background-color: #3187FF;
	color: #FFFFFF !important;
	border-radius: 30px !important;
	-moz-border-radius: 30px !important;
	-webkit-border-radius: 30px !important;
	margin: 5px 0 !important;
	padding: 10px !important;
	border-color: transparent;
	box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
	transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
	line-height: 1.143em;
	font-size: 15px;

	&:disabled {
		background-color: rgba(0, 0, 0, 0.12);
		border-color: transparent;
		box-shadow: none;
	}
}

.btn-cancel-type {
	width: 100%;
	background-color: #6C757D !important;
	color: #FFFFFF !important;
	border-radius: 30px !important;
	-moz-border-radius: 30px !important;
	-webkit-border-radius: 30px !important;
	margin: 5px 0 !important;
	padding: 10px !important;
	border-color: transparent;
	box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
	transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
	line-height: 1.143em;
	font-size: 15px;
}
// Account 화면에 사용한 버튼의 공용스타일

// Account 화면에 사용한 input 공용스타일
.e-float-input {
	.e-float-text {
		color: #333333 !important;
	}

	input {
		flex: 1 1;
		border-bottom-color: rgba(0, 0, 0, 0.16);
	}
}
// Account 화면에 사용한 input 공용스타일

// 각 결과&그래프 TAB 에 사용된 공용스타일
.common-tabs {
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	background-color: #ECECEF;

	.tab-item {
		padding: 11px 54px;
		border: 1px solid #ECECEF;
		border-radius: 10px;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		color: rgba(0, 0, 0, 0.54);
		cursor: pointer;

		// 탭에 마우스 오버할경우
		&:hover {
			opacity: 0.75;
		}
	}

	// 라디오버튼 UI 삭제
	input[name="tab_item"] {
		display: none;

		&:checked + .tab-item {
			background-color: #FFFFFF;
			color: #333333;
			font-weight: 700;
		}
	}
}

.common-tab-content {
	display: none;
	padding-bottom: 2rem;

	&.display-on {
		display: block;
	}
}
// 각 결과&그래프 TAB 에 사용된 공용스타일

// 결과 탭 선택시 보여지는 화면에 사용한 박스 공용 스타일
.common-result-group-wrap {
	.group-title {
		margin-top: 1rem;
		font-size: 14px;
		color: #333333;
	}

	.box-wrap {
		display: flex;
		align-items: center;
		border-radius: 10px;
		-moz-border-radius: 10px;
		-webkit-border-radius: 12px;
		background-color: #FFFFFF;
		padding: 1rem 1.5rem;
		margin: 1rem 0;
		gap: 5px;
		box-shadow: 0 2px 3px 0 rgb(0 0 0 / 16%);

		&:last-child { margin-bottom: 2rem; }

		&.no-data-style {
			margin-top: 0.5rem;
			gap: 0;

			.chart-content-area {
				margin-left: -3px;
			}
		}

		.food-lens-image-wrap {
			flex: 0 0 auto;
			width: 74px;
			height: 74px;
			border-radius: 60px;
			border: 1px solid #EBEBEB;
			position: relative;
			margin-right: 0.375rem;

			img {
				width: 68px;
				height: 68px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 50%;
			}
		}

		.circle-chart-area {
			flex: 0 0 auto;
			margin: 0 1rem 0 0;
			position:relative;
			border-radius: 50%;
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			width: 74px;
			height: 74px;

			&.high {
				border: 5px solid #FF6665;
			}

			&.normal {
				border: 5px solid #A9E271;
			}

			&.low {
				border: 5px solid #1CA8D7;
			}

			&.food {
				border: 5px solid #D691E0;
			}

			&.activity {
				border: 5px solid #F8D358;
			}

			.chart-info {
				position: absolute;
				top: 55%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				color: #333333;

				.value {
					display: block;
					font-size: 18px;
					line-height: 1;
				}
			}
		}

		.chart-content-area {
			display: flex;
			flex-direction: column;
			flex: 1 0;
			color: #333333;

			&.content-add-style {
				padding-left: 1rem;

				&.divide-line {
					padding-left: 2rem;
					border-left: 1px dashed #D9D9D9;
				}
			}

			&.f-base-20 { flex-basis: 20%; }
		}

		.common-ellipse {
			display: flex;
			align-items: center;
			gap: 7px;
			border: 1px solid;
			border-radius: 20px;
			-moz-border-radius: 20px;
			-webkit-border-radius: 20px;
			padding: 2px 14px;

			&.low {
				color: #48A0DD;
				border-color: #48A0DD;
			}

			&.middle {
				color: #80D25B;
				border-color: #80D25B;
			}

			&.high {
				color: #FF6E5C;
				border-color: #FF6E5C;
			}

			&.none {
				color: #999999;
				border-color: #DDDDDD;
			}

			&.sleep {
				color: #7389A2;
				border-color: #CDD7D9;
			}
		}
	}
}

.common-result-expand-card-no-data {
	display: flex;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 100%;
}
// 결과 탭 선택시 보여지는 화면에 사용한 박스 공용 스타일

// 그래프 탭 선택시 보여지는 기간 선택 탭에 사용한 공용스타일
.common-tab-sub-term-wrap {
	display: flex;
	align-items: center;

	span {
		width: 76px;
		padding: 0.2rem 1rem;
		margin: 0.6rem 0;
		cursor: pointer;
		color: #7C828A;
		font-size: 16px;
		text-align: center;
		border-right: 1px dashed #DDDDDD;

		&.active {
			color: #333333;
			font-weight: 700;
		}

		&:first-child {
			margin-left: 1.8rem;
		}

		&:last-child {
			border: none;
		}
	}
}
// 그래프 탭 선택시 보여지는 기간 선택 탭에 사용한 공용스타일

// 그래프 탭에 사용된 카드의 공용스타일
.common-graph-card-area {
	display: flex;
	flex-wrap: wrap;
	gap: 2%;

	.card-wrapper {
		display: flex;
		flex: 1 0;
		align-items: center;
		justify-content: space-between;
		background-color: #FFFFFF;
		border-radius: 12px;
		-moz-border-radius: 12px;
		-webkit-border-radius: 12px;
		padding: 16px 16px 13px 24px;
		min-width: 320px;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
		margin-bottom: 10px;

		.chart-wrapper {
			position: relative;
			margin-right: 6px;

			.canvas-size {
				width: 162px;
				height: 162px;
			}

			.chart-circle-inside {
				position: absolute;
				top: 53%;
				left: 50%;
				transform: translate(-50%, -50%);
				display: flex;
				flex-direction: column;
				font-size: 14px;
				text-align: center;

				span{
					font-size: 38px;
					line-height: 1;
				}
			}
		}

		.chart-info-area {
			flex: 0 1 60%;
			font-size: 16px;
			color: #333333;

			.info {
				display: flex;
				justify-content: space-between;

				&.interval {
					margin-top: 9px;
					margin-bottom: 9px;
				}

				&.underline {
					padding-bottom: 7px;
					border-bottom: 1px dashed #DDDDDD;
				}

				.info-base {
					border-radius: 20px;
					-moz-border-radius: 20px;
					-webkit-border-radius: 20px;
					padding: 2px 10px;

					&.high {
						border: 1px solid #FF6E5C;
						color: #FF6E5C;
					}
					&.middle {
						border: 1px solid #80D25B;
						color: #80D25B;
					}
					&.low {
						border: 1px solid #48A0DD;
						color: #48A0DD;
					}
					&.carbohydrate {
						border: 1px solid #FFD23F;
						color: #FFD23F;
					}
					&.fat {
						border: 1px solid #3BCEAC;
						color: #3BCEAC;
					}
					&.protein {
						border: 1px solid #0EAD69;
						color: #0EAD69;
					}
				}
			}
		}
	}
}

.graph-card-wrapper {
	position: relative;
	background-color: #FFFFFF;
	border-radius: 12px;
	-moz-border-radius: 12px;
	-webkit-border-radius: 12px;
	padding: 1rem;
	min-width: 420px;
	width: 100%;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
	margin-bottom: 10px;

	svg {
		width: 100% !important;
	}

	.graph-no-data {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 18px;
	}
}

@media (max-width: 768px) {
	.common-graph-card-area {
		gap: 5px;

		.card-wrapper {
			padding: 8px 12px 10px 16px;

			.chart-wrapper {
				.canvas-size {
					width: 120px;
					height: 120px;
				}

				.chart-circle-inside {
					font-size: 13px;

					span{
						font-size: 28px;
					}
				}
			}

			.chart-info-area {
				font-size: 14px;
			}
		}
	}

	.graph-card-wrapper {
		min-width: 300px;
		.graph-no-data {
			font-size: 16px;
		}
	}
}
// 그래프 탭에 사용된 카드의 공용스타일

// 건강데이터 공유 팝업의 체크박스 스타일
.share-data-invite-dialog{
	.e-checkbox-wrapper .e-frame {
		border-radius: 50%;
	}
	.e-label {
		font-size: 18px;
	}
}
// 건강데이터 공유 팝업의 체크박스 스타일

/*
 * Font 헬퍼
 * -------------------------------------------
*/
@mixin generate-font-size-options($n, $j: 5) {
	@if $j <= $n {
		@for $i from $j through $n {
			$step: $i*1;
			.f-#{$step} {
				font-size: ($step*1px);
			}
		}
	}
}

@include generate-font-size-options(100);
/*
 * -------------------------------------------
 */

.gray-font { color: #999999; }
.red-font { color: #FF6E5C; }
.white-font { color: #FFFFFF; }

// 결과화면 깨짐증상의 최소 사이즈 계산
@media screen and (max-width : 880px) {
	.common-result-group-wrap {
		.box-wrap.resize {
			flex-direction: column;
			align-items: flex-start;
			padding-left: 2rem;
		}
		.chart-content-area {
			flex-direction: row !important;
			align-items: center;
			gap: 6px;

			&.content-add-style {
				padding-left: 0 !important;

				&.divide-line {
					padding-left: 0 !important;
					border-left: none !important;
				}
			}
		}
	}

	.activity-wrap {
		flex-direction: column;
	}
}
// 결과화면 깨짐증상의 최소 사이즈 계산

// 공통 input style 조정
input {
	&:focus {
		outline: none;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&::placeholder {
		color: #cccccc;
	}
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
// 공통 input style 조정

// 공통 배경변경
.background-white {
	background-color: #FFFFFF !important;
}

.dialog-max-size {
	max-width: 1200px;
}

.change-pass-dialog-size {
	max-width: 400px;
	min-height: 390px;
}

.dataTargetUserDropdown {
	background-color:#FFFFFF;

	.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
	.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left)
	{
		border: none;
		padding-top: 2px;
		padding-left: 10px;
		padding-right: 6px;
		width: 230px !important;
	}
}

.graph-no-data-dynamic{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 1.0rem;
}

.hidden-overflow{
	overflow: hidden;
}

// 포스트에 사용중인 swiper 스타일 외부변경
.common-post-image-slide-wrap {
	position: relative;
	background-color: #FCFCFC;
	margin: 0.5rem;

	& > .inner-images-items {
		max-width: 630px;
		margin: 0 auto;

		.swiper-slide {
			width: 630px !important;

			.image-item {
				width: 100%;

				& > img {
					width: 100%;
				}
			}
		}
	}

	& > .btn-slide-move-common {
		background-color: transparent;
		background-image: none;
		border: none;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;

		&.prev {
			left: 0.25rem;
		}

		&.next {
			right: 0.25rem;
		}
	}
}

@media (max-width: 1023px) {
	.common-post-image-slide-wrap {
		& > .inner-images-items {
			max-width: 420px;

			.swiper-slide {
				width: 420px !important;
			}
		}
	}
}

@media (max-width : 767px) {
	.dataTargetUserDropdown {
		.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
		.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left)
		{
			width: 160px !important;
		}
	}

	.common-post-image-slide-wrap {
		& > .inner-images-items {
			max-width: 260px;

			.swiper-slide {
				width: 260px !important;
			}
		}
	}
}
