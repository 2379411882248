.e-toast-container .e-toast {

	&.e-toast-success {
		background-color: #D4EDDA;
		border: 1px solid #C3E6CB;
		opacity: 0.9;

		&:hover {
			background-color: #D4EDDA;
			color: #155724;
		}

		.e-toast-icon {
			color: #155724;
		}

		.e-toast-message .e-toast-content {
			padding: 0;
			color: #155724;
		}

		.e-toast-close-icon {
			color: #155724;
		}
	}

	&.e-toast-warning {
		background-color: #FFC266;
		border: 1px solid #FFA926;
		opacity: 0.9;

		&:hover {
			background-color: #FFC266;
			color: #8C4F00;
		}

		.e-toast-icon {
			color: #8C4F00;
		}

		.e-toast-message .e-toast-content {
			padding: 0;
			color: #8C4F00;
		}

		.e-toast-close-icon {
			color: #8C4F00;
		}
	}

	&.e-toast-danger {
		background-color: #F8D7DA;
		border: 1px solid #F5C6CB;
		opacity: 0.9;

		&:hover {
			background-color: #F8D7DA;
			color: #721C24;
		}

		.e-toast-icon {
			color: #721C24;
		}

		.e-toast-message .e-toast-content {
			padding: 0;
			color: #721C24;
		}

		.e-toast-close-icon {
			color: #721C24;
		}
	}
}

// media setting
$phone: "only screen and (max-width : 768px)";
$desktop: "screen and (min-width : 768px)";

@media #{$phone} {
	.e-toast-container .e-toast {
		width: 80% !important;
	}
}
@media #{$desktop} {
	.e-toast-container .e-toast {
		width: 60% !important;
		max-width: 700px;
	}
}
